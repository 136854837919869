export default {
  area: "栾川乡数智管理平台",
  objectName: "",
  //url
  hostUrl: 'https://yyjp.zhsq.cloud',
  apiHttpsUrl: 'https://lcxapi.anluoyun.cn/',
  apiRequestUrl: 'https://lcxapi.anluoyun.cn/api',
  apiUploadUrl: 'https://lcxapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadFiles: 'https://lcxapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadvideo: 'https://lcxapi.anluoyun.cn/api/UploadFile/UploadQiniuVideo',
}