import request from '@/utils/request'
// 获取商铺列表
export function WeGetShopPage (query) {
  return request({
    url: 'Shop/WeGetShopPage',
    method: 'get',
    params: query
  })
}
// 获取商铺类型列表
export function WxGetShopTypeList (query) {
  return request({
    url: 'Shop/WxGetShopTypeList',
    method: 'get',
    params: query
  })
}
// 获取商品列表
export function WxGetGoodsPage (query) {
  return request({
    url: 'Goods/WxGetGoodsPage',
    method: 'get',
    params: query
  })
}
// 获取商品类型列表
export function WxGetGoodsSortList (query) {
  return request({
    url: 'GoodsSort/WxGetGoodsSortList',
    method: 'get',
    params: query
  })
}
// 获取商品详情
export function WxGetMyShop (query) {
  return request({
    url: 'Shop/WxGetMyShop',
    method: 'get',
    params: query
  })
}
// 生成订单
export function WeGenerateOrder (data) {
  return request({
    url: 'ShopOrder/WeGenerateOrder',
    method: 'post',
    data
  })
}
// 提交订单
export function WePlaceOrder (data) {
  return request({
    url: 'ShopOrder/WePlaceOrder',
    method: 'post',
    data
  })
}
// 撤销订单
export function WeRevokeOrder (data) {
  return request({
    url: 'ShopOrder/WeRevokeOrder',
    method: 'post',
    data
  })
}
// 删除订单
export function WeDeleteOrder (data) {
  return request({
    url: 'ShopOrder/WeDeleteOrder',
    method: 'post',
    data
  })
}
// 增加订单商品
export function WeSaveOrderService (data) {
  return request({
    url: 'ShopOrder/WeSaveOrderService',
    method: 'post',
    data
  })
}
// 删除订单商品
export function WeDeleteOrderGoods (data) {
  return request({
    url: 'ShopOrder/WeDeleteOrderGoods',
    method: 'post',
    data
  })
}
// 获取订单内列表
export function WeGetOrderGoodsList (query) {
  return request({
    url: 'ShopOrder/WeGetOrderGoodsList',
    method: 'get',
    params: query
  })
}
// 获取我的订单列表，居安端居民使用
export function WeGetMyOrderPage (query) {
  return request({
    url: 'ShopOrder/WeGetMyOrderPage',
    method: 'get',
    params: query
  })
}
// 获取我的订单详情
export function WeGetMyOrder (query) {
  return request({
    url: 'ShopOrder/WeGetMyOrder',
    method: 'get',
    params: query
  })
}
// 获取绑定信息
export function WeGetMyBindInfoPage (query) {
  return request({
    url: 'BindInfo/WeGetMyBindInfoPage',
    method: 'get',
    params: query
  })
}
// 评价订单
export function WeEvaluateOrder (data) {
  return request({
    url: 'OrderEval/WeEvaluateOrder',
    method: 'post',
    data
  })
}

// 获取促销活动列表-居民
export function WxGetPromotionPage (query) {
  return request({
    url: 'Promotion/WxGetPromotionPage',
    method: 'get',
    params: query
  })
}

// 获取促销活动报名列表
export function WxGetMyActEnrolPage (query) {
  return request({
    url: 'Promotion/WxGetMyActEnrolPage',
    method: 'get',
    params: query
  })
}

// 获取促销活动报名列表
export function WxGetActEnrolPage (query) {
  return request({
    url: 'Promotion/WxGetActEnrolPage',
    method: 'get',
    params: query
  })
}
// 完成活动交易-商铺
export function WxFinishMyTransaction (data) {
  return request({
    url: 'Promotion/WxFinishMyTransaction',
    method: 'post',
    data
  })
}
// 拒绝活动交易-商铺
export function WxRefuseMyTransaction (data) {
  return request({
    url: 'Promotion/WxRefuseMyTransaction',
    method: 'post',
    data
  })
}
// 获取商铺订单列表，居民端商铺小程序使用
export function WxGetShopOrderPage (query) {
  return request({
    url: 'ShopOrder/WxGetShopOrderPage',
    method: 'get',
    params: query
  })
}


// 居民端-获取民宿旅馆列表
export function WxGetHotelPage (query) {
  return request({
    url: 'Hotel/Wx_GetHotelPage',
    method: 'get',
    params: query
  })
}

// 居民端-获取民宿旅馆列表
export function HGetMyHotelList (query) {
  return request({
    url: 'Hotel/H_GetMyHotelList',
    method: 'get',
    params: query
  })
}
// 民宿旅馆-获取我的从业人员列表
export function HGetMyStaffList (query) {
  return request({
    url: 'HotelStaffStaff/H_GetMyStaffList',
    method: 'get',
    params: query
  })
}
// 判断当前微信用户是否绑定旅馆
export function HIsBindHotel (data) {
  return request({
    url: 'HotelStaffStaff/H_IsBindHotel',
    method: 'post',
    data
  })
}
// 绑定旅馆
export function HBindHotel (data) {
  return request({
    url: 'HotelStaffStaff/H_BindHotel',
    method: 'post',
    data
  })
}

// 民宿旅馆-获取我的旅客列表
export function HGetMyGuestPage (query) {
  return request({
    url: 'Guest/H_GetMyGuestPage',
    method: 'get',
    params: query
  })
}
// 民宿旅馆-获取指定民宿旅馆的房间列表
export function WxGetHotelRoomList (query) {
  return request({
    url: 'HotelRoom/H_GetMyUseHotelRoomList',
    method: 'get',
    params: query
  })
}
// 民宿旅馆-删除房间信息
export function HDeleteHotelRoom (data) {
  return request({
    url: 'HotelRoom/H_DeleteHotelRoom',
    method: 'post',
    data
  })
}

// 民宿旅馆-获取我的可用房间列表，主要用于旅客登记时展示房态图
export function HGetMyUseHotelRoomList (query) {
  return request({
    url: 'HotelRoom/H_GetMyUseHotelRoomList',
    method: 'get',
    params: query
  })
}

// 民宿宾馆-按房间换房
export function HChangeRoomOfAll (data) {
  return request({
    url: 'Guest/H_ChangeRoomOfAll',
    method: 'post',
    data
  })
}
// 民宿宾馆-按房间退房
export function HLeaveRooOfAll (data) {
  return request({
    url: 'Guest/H_LeaveRooOfAll',
    method: 'post',
    data
  })
}
// 民宿宾馆-单个旅客退房
export function HLeaveRoom (data) {
  return request({
    url: 'Guest/H_LeaveRoom',
    method: 'post',
    data
  })
}

// 民宿宾馆-单个旅客换房
export function HChangeRoom (data) {
  return request({
    url: 'Guest/H_ChangeRoom',
    method: 'post',
    data
  })
}


// 民宿旅馆-获取房间旅客列表
export function HGetLiveGuestList (query) {
  return request({
    url: 'Guest/H_GetLiveGuestList',
    method: 'get',
    params: query
  })
}

// 居民端-小程序获取职位信息列表
export function WxGetJobPage (query) {
  return request({
    url: 'Job/Wx_GetJobPage',
    method: 'get',
    params: query
  })
}
// 获取职业类型信息列表
export function GetAbilityList (query) {
  return request({
    url: 'Ability/GetAbilityList',
    method: 'get',
    params: query
  })
}

// 民宿旅馆-保存职位
export function HSaveJob (data) {
  return request({
    url: 'Job/H_SaveJob',
    method: 'post',
    data
  })
}

// 民宿旅馆-获取职位信息详情
export function HGetJob (query) {
  return request({
    url: 'Job/H_GetJob',
    method: 'get',
    params: query
  })
}
// 民宿旅馆-删除职位
export function REDeleteJob (data) {
  return request({
    url: 'Job/RE_DeleteJob',
    method: 'post',
    data
  })
}
// 居民端-小程序获取职位详情
export function WeGetJobDetail (query) {
  return request({
    url: 'Job/WeGetJobDetail',
    method: 'get',
    params: query
  })
}

// 民宿旅馆-保存房间类型信息
export function HGetMyRoomTypeList (query) {
  return request({
    url: 'RoomType/H_GetMyRoomTypeList',
    method: 'get',
    params: query
  })
}
// 民宿旅馆-删除房间类型信息
export function HDeleteRoomType (data) {
  return request({
    url: 'RoomType/H_DeleteRoomType',
    method: 'post',
    data
  })
}
// 民宿旅馆-获取待确认的预订房间数和待入住的预订房间数
export function HGetMyHotelRoomNum (query) {
  return request({
    url: 'HotelRoom/H_GetMyHotelRoomNum',
    method: 'get',
    params: query
  })
}

// 民宿旅馆-获取入住订单列表
export function HGetPreHotelPage (query) {
  return request({
    url: 'PreHotel/H_GetPreHotelPage',
    method: 'get',
    params: query
  })
}
// 居民端-获取我的入住订单列表
export function WxGetMyPreHotelPage (query) {
  return request({
    url: 'PreHotel/Wx_GetMyPreHotelPage',
    method: 'get',
    params: query
  })
}
// 居民端-获取我的看房入住订单详情
export function WxGetMyPreHotel (query) {
  return request({
    url: 'PreHotel/Wx_GetMyPreHotel',
    method: 'get',
    params: query
  })
}
// 居民端-删除入住订单
export function WxDeletePreHotel (data) {
  return request({
    url: 'PreHotel/Wx_DeletePreHotel',
    method: 'post',
    data
  })
}
// 居民端-取消入住订单
export function WxCancelPreHotel (data) {
  return request({
    url: 'PreHotel/Wx_CancelPreHotel',
    method: 'post',
    data
  })
}

// 房主端-获取入住订单详情
export function HGetPreHotel (query) {
  return request({
    url: 'PreHotel/H_GetPreHotel',
    method: 'get',
    params: query
  })
}